import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import Link from '@/components/Link';
import ArrowIcon from '@/assets/svg/arrow-down.svg';
import { media } from '@/utils/mixin';
import { getPhoneLink } from '@/utils/common';
import Button from '@/components/Button';

import { ServiceDealerCardProps } from './types';

function ServiceDealerCard({
  image,
  name,
  city,
  address,
  workTime,
  phone,
  brands,
  lat,
  lng,
  onClick,
}: ServiceDealerCardProps) {
  const [isOpenedBrands, setOpenedBrands] = useState(false);

  const handleShowMoreClick = () => {
    setOpenedBrands(!isOpenedBrands);
  };

  const handleDealerClick = () => {
    onClick?.({
      image,
      name,
      city,
      address,
      workTime,
      phone,
      brands,
      lat,
      lng,
    });
  };

  return (
    <Component>
      {image && <Image loading="lazy" src={image} />}

      <Content>
        <Name>{name}</Name>
        <Address>
          г. {city}, {address}
        </Address>

        <Row>
          <WorkTime>{workTime}</WorkTime>
          <Phone to={getPhoneLink(phone)}>{phone}</Phone>
        </Row>

        <BrandsTitle>Обслуживаемые марки:</BrandsTitle>
        <BrandsList>
          {brands &&
            brands.length !== 0 &&
            brands
              .slice(0, 3)
              .map((brand, index) => <Brand key={index}>{brand}</Brand>)}

          {brands.length > 3 && (
            <ShowMore onClick={handleShowMoreClick} isOpened={isOpenedBrands}>
              ЕЩЕ <ArrowIcon />
            </ShowMore>
          )}

          {isOpenedBrands && (
            <BrandsListPopup>
              {brands &&
                brands.length !== 0 &&
                brands
                  .slice(0, 3)
                  .map((brand, index) => <Brand key={index}>{brand}</Brand>)}

              <ShowMore onClick={handleShowMoreClick} isOpened={isOpenedBrands}>
                СКРЫТЬ <ArrowIcon />
              </ShowMore>

              {brands &&
                brands.length !== 0 &&
                brands
                  .slice(3)
                  .map((brand, index) => <Brand key={index}>{brand}</Brand>)}
            </BrandsListPopup>
          )}
        </BrandsList>

        <Action variant="outlined" onClick={handleDealerClick}>
          Записаться на сервис
        </Action>
      </Content>
    </Component>
  );
}

export default ServiceDealerCard;

export const Component = styled.div`
  display: flex;

  ${media.mobile(css`
    flex-direction: column;
  `)}
`;

export const Image = styled(Picture)`
  flex: 1 1 290px;
  max-width: 290px;

  ${media.mobile(css`
    flex: auto;
    max-width: none;
    padding-top: 53.032%;
  `)}

  picture,
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 10px 0 0 10px;

    ${media.mobile(css`
      border-radius: 10px 10px 0 0;
      inset: 0;
    `)}
  }

  img {
    object-fit: cover;
  }
`;

export const Content = styled.div`
  flex: 1;
  padding: 20px;
  border-radius: 0 10px 10px 0;
  border: 1px solid ${(props) => props.theme.gray1300_alpha50};
  border-left-color: transparent;
  background: ${(props) => props.theme.white_1};

  ${media.mobile(css`
    padding: 20px 20px 40px;
    border-radius: 0 0 10px 10px;
    border-top: transparent;
    border-left-color: ${(props) => props.theme.gray1300_alpha50};
  `)}
`;

export const Name = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: ${(props) => props.theme.black};
`;

export const Address = styled.p`
  font-size: 16px;
  line-height: 19px;
  margin-top: 10px;
  color: ${(props) => props.theme.blue100};
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;

  ${media.mobile(css`
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
  `)}
`;

export const WorkTime = styled.p`
  font-size: 16px;
  line-height: 19px;
  margin-right: 10px;
  color: ${(props) => props.theme.blue100};

  ${media.mobile(css`
    margin-right: 0;
  `)}
`;

export const Phone = styled(Link)`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: ${colors.main};

  ${media.mobile(css`
    margin-top: 10px;
  `)}
`;

export const BrandsTitle = styled.p`
  font-size: 16px;
  line-height: 19px;
  margin-top: 10px;
  color: ${(props) => props.theme.blue100};
`;

export const BrandsList = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 430px;
  margin-top: 5px;
`;

export const Brand = styled.div`
  padding: 8px 20px;
  height: 32px;
  font-size: 14px;
  line-height: 16px;
  background: #ebecf0;
  border-radius: 6px;
  margin: 5px 10px 5px 0;
  color: ${colors.blue600};

  ${media.mobile(css`
    margin: 5px 5px 5px 0;
  `)}
`;

export const ShowMore = styled.button<{ isOpened?: boolean }>`
  font-size: 12px;
  line-height: 14px;
  margin: 5px 10px 5px 0;
  color: ${colors.main};

  ${media.mobile(css`
    margin: 5px 5px 5px 0;
  `)}

  svg {
    margin-left: 5px;
    transition: 0.3s;

    path {
      fill: ${colors.main};
    }
  }

  ${({ isOpened }) =>
    isOpened &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;

export const BrandsListPopup = styled.div`
  z-index: 5;
  position: absolute;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 5px;
  margin: 0 -5px;
  width: 100%;
  top: 0;
  background: ${(props) => props.theme.white_1};
  box-shadow: ${(props) => props.theme.blackShadow100};
  border-radius: 10px;
`;

export const Action = styled(Button)`
  max-width: 300px;
  margin-top: 20px;
`;
