import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

import { ServiceDescriptionBlockProps } from './types';

function ServiceDescriptionBlock({ text }: ServiceDescriptionBlockProps) {
  return <Component dangerouslySetInnerHTML={{ __html: text }} />;
}

export default ServiceDescriptionBlock;

const Component = styled.div`
  color: ${(props) => props.theme.black};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 20px;
    color: ${(props) => props.theme.black200};

    ${media.mobile(css`
      font-size: 20px;
      line-height: 23px;
      margin-bottom: 10px;
    `)}
  }

  p {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 20px;
    color: ${(props) => props.theme.blue100};

    ${media.mobile(css`
      margin-bottom: 10px;
    `)}
  }

  ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px 20px;
    list-style-type: none !important;
    margin-bottom: 20px;

    ${media.tabletSmall(css`
      grid-template-columns: repeat(1, 1fr);
    `)}

    ${media.mobile(css`
      margin-bottom: 10px;
    `)}
    
    li {
      position: relative;
      padding-left: 14px;
      font-size: 16px;
      line-height: 19px;
      color: ${(props) => props.theme.blue100};

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 9.5px;
        width: 9px;
        height: 2px;
        background: ${colors.main};
      }
    }
  }

  ol {
    list-style-type: decimal !important;
    margin-left: 17px;
    margin-bottom: 20px;

    ${media.mobile(css`
      margin-bottom: 10px;
    `)}

    li {
      font-size: 16px;
      line-height: 19px;
      color: ${(props) => props.theme.blue100};

      &:not(:first-child) {
        margin-top: 5px;
      }
    }
  }
`;
