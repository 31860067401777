import React from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';

import { ContentContainer } from '@/modules/Service/common.styles';
import { media } from '@/utils/mixin';
import ServiceBanner from '@/modules/Service/components/ServiceBanner';
import { useTypedSelector } from '@/store/store';
import { getServiceBrandLanding } from '@/store/reducers/service';
import { Breadcrumbs } from '@/components/Breadcrumbs';

function Hero() {
  const router = useRouter();
  const serviceBrandLanding = useTypedSelector(getServiceBrandLanding);

  if (!serviceBrandLanding) {
    return null;
  }

  const { name, bannerTitle, bannerText, bannerImage } = serviceBrandLanding;

  return (
    <Component>
      <ContentContainer>
        <BreadcrumbsWrapper>
          <Breadcrumbs
            links={[
              { label: 'Главная', link: '/' },
              {
                label: 'Сервис',
                link: '/service',
              },
              { label: name ?? '', link: router.asPath },
            ]}
          />
        </BreadcrumbsWrapper>

        <ServiceBannerWrapper>
          <ServiceBanner
            title={name ? `Сервис ${name}` : ''}
            subtitle={bannerTitle ?? ''}
            description={bannerText ?? ''}
            image={bannerImage.desktop}
            imageMobile={bannerImage.mobile}
          />
        </ServiceBannerWrapper>
      </ContentContainer>
    </Component>
  );
}

export default Hero;

const Component = styled.section`
  padding: 40px 0 30px;

  ${media.mobile(css`
    padding: 20px 0;
  `)}
`;

const BreadcrumbsWrapper = styled.div`
  ${media.tablet(css`
    display: none;
  `)}
`;

const ServiceBannerWrapper = styled.div`
  margin-top: 20px;

  ${media.tablet(css`
    margin-top: 0;
  `)}
`;
