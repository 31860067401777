import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import { media } from '@/utils/mixin';
import { ContentContainer } from '@/modules/Service/common.styles';
import StockFilterSelect from '@/components/StockFilterSelect';
import ServiceTabs from '@/modules/Service/components/ServiceTabs';
import { OptionType } from '@/typings/common';
import Button from '@/components/Button';

import { DealersBlockProps } from './types';
import { serviceTabsData } from './constants';
import DealersList from './components/DealersList';
import DealersSlider from './components/DealersSlider';
import DealersMap from './components/DealersMap';

function DealersBlock({ title, dealers, options }: DealersBlockProps) {
  const [activeTab, setActiveTab] = useState<OptionType>(serviceTabsData[0]);
  const [isShowMore, setShowMore] = useState(false);
  const [filteredDealers, setFilteredDealers] = useState(dealers);
  const isMobile = useMedia('(max-width: 767.99px)');

  const brandsOptions = [
    { label: 'Все марки', value: 'Все марки' },
    ...(options?.brands ?? []),
  ];

  const citiesOptions = [
    { label: 'Все города', value: 'Все города' },
    ...(options?.cities ?? []),
  ];

  const [selectedBrand, setSelectedBrand] = useState<OptionType>(
    brandsOptions[0]
  );

  const [selectedCity, setSelectedCity] = useState<OptionType>(
    citiesOptions[0]
  );

  const handleShowMore = () => {
    setShowMore(!isShowMore);
  };

  const handleChangeBrand = (brand: OptionType) => {
    setSelectedBrand(brand);

    if (brand.label === 'Все марки') {
      setFilteredDealers(
        dealers.filter((dealer) =>
          selectedCity.label !== 'Все города'
            ? dealer.city === selectedCity.label
            : true
        )
      );
    } else {
      setFilteredDealers(
        dealers.filter(
          (dealer) =>
            (selectedCity.label !== 'Все города'
              ? dealer.city === selectedCity.label
              : true) && dealer.brands.includes(brand.label)
        )
      );
    }

    setShowMore(false);
  };

  const handleChangeCity = (city: OptionType) => {
    setSelectedCity(city);

    if (city.label === 'Все города') {
      setFilteredDealers(
        dealers.filter((dealer) =>
          selectedBrand.label !== 'Все марки'
            ? dealer.brands.includes(selectedBrand.label)
            : true
        )
      );
    } else {
      setFilteredDealers(
        dealers.filter(
          (dealer) =>
            (selectedBrand.label !== 'Все марки'
              ? dealer.brands.includes(selectedBrand.label)
              : true) && dealer.city === city.label
        )
      );
    }

    setShowMore(false);
  };

  if (!dealers || dealers.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Title>{title}</Title>
        <Filters>
          <Filter>
            <StockFilterSelect
              value={selectedBrand}
              options={brandsOptions}
              onChange={handleChangeBrand}
            />
          </Filter>

          <Filter>
            <StockFilterSelect
              value={selectedCity}
              options={citiesOptions}
              onChange={handleChangeCity}
            />
          </Filter>
        </Filters>

        <ServiceTabsWrapper>
          <ServiceTabs
            options={serviceTabsData}
            activeTab={activeTab.value}
            onChange={setActiveTab}
          />
        </ServiceTabsWrapper>

        <Content>
          {activeTab.value === serviceTabsData[0].value ? (
            <React.Fragment>
              {!isMobile ? (
                <React.Fragment>
                  <DealersList
                    dealers={
                      !isShowMore
                        ? filteredDealers.slice(0, 4)
                        : filteredDealers
                    }
                  />

                  {filteredDealers && filteredDealers.length > 4 && (
                    <Actions>
                      <ShowMore variant="outlined" onClick={handleShowMore}>
                        {!isShowMore ? 'Показать еще' : 'Скрыть'}
                      </ShowMore>
                    </Actions>
                  )}
                </React.Fragment>
              ) : (
                <DealersSlider dealers={filteredDealers} />
              )}
            </React.Fragment>
          ) : (
            <DealersMap
              dealers={filteredDealers.map((dealer) => ({
                ...dealer,
                image: !isMobile ? dealer.image : '',
              }))}
              isMobile={isMobile}
            />
          )}
        </Content>
      </ContentContainer>
    </Component>
  );
}

export default DealersBlock;

const Component = styled.section`
  padding: 30px 0;

  ${media.mobile(css`
    padding: 20px 0;
  `)}
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
  `)}
`;

const Filters = styled.div`
  display: flex;
  margin-top: 20px;

  ${media.mobile(css`
    flex-direction: column;
  `)}
`;

const Filter = styled.div`
  flex: 1 1 445px;
  max-width: 445px;

  ${media.mobile(css`
    flex: auto;
    max-width: none;
  `)}

  &:not(:first-child) {
    margin-left: 20px;

    ${media.mobile(css`
      margin-left: 0;
      margin-top: 20px;
    `)}
  }
`;

const ServiceTabsWrapper = styled.div`
  margin-top: 40px;
`;

const Content = styled.div`
  margin-top: 20px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const ShowMore = styled(Button)`
  max-width: 289px;
`;
