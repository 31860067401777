import React from 'react';

import OffersBlock from '@/modules/Service/blocks/OffersBlock';
import {
  getServiceBrandLanding,
  getServiceBrandOffers,
} from '@/store/reducers/service';
import { useTypedSelector } from '@/store/store';

function Offers() {
  const offers = useTypedSelector(getServiceBrandOffers);
  const serviceBrandLanding = useTypedSelector(getServiceBrandLanding);

  if (
    !offers ||
    offers.length === 0 ||
    !serviceBrandLanding?.blogTags ||
    serviceBrandLanding.blogTags.length === 0
  ) {
    return null;
  }

  return (
    <OffersBlock
      title={`Акции по сервису ${serviceBrandLanding?.name}`}
      offers={offers.map(({ title, url, image, landscapeImage }) => ({
        title: title ?? '',
        url: url ?? '',
        image: offers.length !== 1 ? image : landscapeImage,
      }))}
    />
  );
}

export default Offers;
