import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { ShortCodeService } from '@tager/web-core';

import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { handleServiceClick } from '@/modules/Service/common.helpers';
import Button from '@/components/Button';

import { ServiceIntroBlockProps } from './types';

const priceShortCodeService = new ShortCodeService('price', ['label', 'value']);

function ServiceIntroBlock({ title, text, image }: ServiceIntroBlockProps) {
  const [content, setContent] = useState<string>(text);

  useEffect(() => {
    if (!text) {
      return;
    }

    setContent(priceShortCodeService.replaceShortCodeByElement(text));
  }, [text]);

  useEffect(() => {
    priceShortCodeService.renderComponent(({ label, value }) => {
      return value ? (
        <Price>
          <PricePrefix>{label}</PricePrefix> {value}
        </Price>
      ) : null;
    });
  }, [content]);

  return (
    <Component>
      <Content>
        <Title>{title}</Title>

        <Action variant="contained" onClick={() => handleServiceClick()}>
          Записаться на сервис
        </Action>

        {content && <Main dangerouslySetInnerHTML={{ __html: content }} />}
      </Content>

      {image && <Image loading="lazy" src={image} alt={title + ' #1'} />}
    </Component>
  );
}

export default ServiceIntroBlock;

const Component = styled.div`
  display: flex;
  align-items: flex-start;

  ${media.tablet(css`
    flex-direction: column-reverse;
  `)}

  ${media.mobile(css`
    margin: 0 -15px;
    align-items: initial;
  `)}
`;

const Content = styled.div`
  flex: 1 1 57%;
  max-width: 57%;

  ${media.tablet(css`
    flex: auto;
    max-width: none;
    margin-top: 20px;
  `)}

  ${media.mobile(css`
    margin: 20px 15px 0;
  `)}
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  margin: 0;
  color: ${(props) => props.theme.black};
`;

const Action = styled(Button)`
  display: none;
  margin-top: 20px;

  ${media.mobile(css`
    display: block;
  `)}
`;

const Main = styled.div`
  margin-top: 20px;
  color: ${(props) => props.theme.black};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 20px;

    ${media.tablet(css`
      font-size: 20px;
      line-height: 23px;
    `)}
  }

  p {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 20px;
    color: ${(props) => props.theme.blue100};

    ${media.mobile(css`
      margin-bottom: 10px;
    `)}
  }

  ul,
  ol {
    margin-bottom: 20px;

    li {
      position: relative;
      font-size: 16px;
      line-height: 19px;
      color: ${(props) => props.theme.blue100};

      &:not(:first-child) {
        margin-top: 5px;
      }
    }
  }

  ul {
    list-style-type: none !important;

    li {
      padding-left: 14px;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 8px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: ${colors.main};
      }
    }
  }

  ol {
    list-style-type: decimal !important;
    margin-left: 17px;
  }
`;

const Price = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  margin-top: 20px;
  color: ${colors.main};
`;

const PricePrefix = styled.span`
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.black};
`;

const Image = styled(Picture)`
  flex: 1 1 43%;
  max-width: 444px;
  padding-top: 29.662%;
  margin-left: 20px;
  margin-top: 49px;

  ${media.tablet(css`
    flex: 1 1 350px;
    width: 100%;
    padding-top: 0;
    margin-left: 0;
    margin-top: 0;
  `)}

  ${media.mobile(css`
    flex: auto;
    max-width: none;
    padding-top: 78.89%;
  `)}

  picture,
  img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;

    ${media.mobile(css`
      position: absolute;
      inset: 0;
    `)}
  }
`;
