import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';

import { ServicesBlockProps } from '@/modules/Service/blocks/ServicesBlock/types';
import { Title } from '@/modules/Service/blocks/ServicesBlock/common.styles';
import ServiceCard from '@/modules/Service/components/ServiceCard';
import { colors } from '@/constants/theme';

function Mobile({ title, items }: ServicesBlockProps) {
  return (
    <Component>
      <Title>{title}</Title>
      <Content>
        <Swiper
          slidesPerView="auto"
          speed={600}
          spaceBetween={20}
          pagination={{
            el: '.swiper-pagination-services',
            type: 'bullets',
            clickable: true,
          }}
        >
          {items &&
            items.length !== 0 &&
            items.map((item, index) => (
              <SwiperSlide key={index}>
                <ServiceCard {...item} />
              </SwiperSlide>
            ))}
        </Swiper>
      </Content>

      <Pagination className="swiper-pagination-services" />
    </Component>
  );
}

export default Mobile;

const Component = styled.div`
  position: relative;

  .swiper-slide {
    height: auto;

    & > a {
      height: 100%;
    }
  }
`;

const Content = styled.div`
  margin-top: 20px;
`;

const Pagination = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .swiper-pagination-bullet {
    cursor: pointer;
    width: 7px;
    height: 7px;
    margin: 0 3px;
    background: ${(props) => props.theme.black};
  }

  .swiper-pagination-bullet-active {
    background: ${colors.main};
  }
`;
