import React from 'react';

import DealersBlock from '@/modules/Service/blocks/DealersBlock';
import { useTypedSelector } from '@/store/store';
import { getServiceBrands, getServiceDealers } from '@/store/reducers/service';
import {
  getServiceDealersData,
  getServicePageOptions,
  handleServiceClick,
} from '@/modules/Service/common.helpers';
import { useServiceContext } from '@/modules/Service/common.hooks';

function Dealers() {
  const serviceDealers = useTypedSelector(getServiceDealers);
  const serviceBrands = useTypedSelector(getServiceBrands);

  const { setSelectedDealer } = useServiceContext();

  const { brands, cities } = getServicePageOptions(
    serviceDealers,
    serviceBrands
  );
  const dealers = getServiceDealersData(serviceDealers);

  return (
    <DealersBlock
      title="Сервисные центры"
      dealers={dealers.map((dealer) => ({
        ...dealer,
        onClick: (dealer) => {
          handleServiceClick(dealer, setSelectedDealer);
        },
      }))}
      options={{ brands, cities }}
    />
  );
}

export default Dealers;
