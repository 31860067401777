import React from 'react';
import styled from 'styled-components';

import { ServiceProvider } from '@/modules/Service/ServiceProvider';
import Layout from '@/layout';

import { useServiceMainEventMicromarking } from './hooks';
import Hero from './components/Hero';
import Brands from './components/Brands';
import Services from './components/Services';
import Benefits from './components/Benefits';
import Form from './components/Form';
import Offers from './components/Offers';
import Dealers from './components/Dealers';
import Consultation from './components/Consultation';

export function ServiceMain() {
  const eventMicromarking = useServiceMainEventMicromarking();

  return (
    <Layout>
      <ServiceProvider>
        <Component>
          <Hero />
          <Brands />
          <Services />
          <Form />
          <Offers />
          <Benefits />
          <Dealers />
          <Consultation />
        </Component>
      </ServiceProvider>

      {eventMicromarking.map((event, index) => (
        <script
          key={index}
          id="serviceMainEvent"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: event,
          }}
        />
      ))}
    </Layout>
  );
}

const Component = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
`;
