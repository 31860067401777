import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/Service/common.styles';
import { colors } from '@/constants/theme';
import Link from '@/components/Link';
import ArrowIcon from '@/assets/svg/arrow-down.svg';
import { media } from '@/utils/mixin';
import { useTypedSelector } from '@/store/store';
import { getServiceBrands } from '@/store/reducers/service';
import Picture from '@/components/Picture';

function Brands() {
  const brands = useTypedSelector(getServiceBrands);

  const [isOpenedBrands, setOpenedBrands] = useState(false);

  const handleShowMoreClick = () => {
    setOpenedBrands(!isOpenedBrands);
  };

  if (!brands || brands.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Inner>
          {brands.slice(0, 11).map(({ name, url, logo }, index) => (
            <Column key={index}>
              <Brand to={url}>
                {logo && <Logo loading="lazy" src={logo} />}
                <Name>{name}</Name>{' '}
              </Brand>
            </Column>
          ))}

          {brands.slice(11).map(({ name, url, logo }, index) => (
            <Column key={index} isNotDisplay={!isOpenedBrands}>
              <Brand to={url}>
                {logo && <Logo loading="lazy" src={logo} />}
                <Name>{name}</Name>{' '}
              </Brand>
            </Column>
          ))}

          {brands.length > 11 && (
            <Column>
              <ShowMore onClick={handleShowMoreClick} isOpened={isOpenedBrands}>
                {!isOpenedBrands ? 'ЕЩЕ' : 'СКРЫТЬ'} <ArrowIcon />
              </ShowMore>
            </Column>
          )}
        </Inner>
      </ContentContainer>
    </Component>
  );
}

export default Brands;

const Component = styled.section`
  padding: 30px 0;

  ${media.mobile(css`
    padding: 40px 0 20px;
  `)}
`;

const Inner = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
  padding: 32px 116px;
  background: ${(props) => props.theme.white_1};
  box-shadow: ${(props) => props.theme.blackShadow100};
  border-radius: 10px;

  ${media.laptop(css`
    padding: 32px 70px;
  `)}

  ${media.tablet(css`
    padding: 20px;
    grid-template-columns: repeat(4, 1fr);
  `)}
  
  ${media.mobile(css`
    grid-template-columns: repeat(2, 1fr);
  `)}
`;

const Column = styled.div<{ isNotDisplay?: boolean }>`
  display: flex;
  align-items: center;

  ${({ isNotDisplay }) =>
    isNotDisplay &&
    css`
      display: none;
    `}
`;

const Name = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  border-bottom: 1px solid transparent;
  transition: 0.3s;
  color: ${(props) => props.theme.blue100};
`;

const Brand = styled(Link)`
  display: flex;
  align-items: center;

  &:hover {
    ${Name} {
      color: ${colors.main};
      border-bottom-color: ${colors.main};
    }
  }
`;

const Logo = styled(Picture)`
  padding: 2px;
  flex: 0 0 36px;
  width: 36px;
  height: 36px;
  margin-right: 8px;
  border-radius: 10px;
  background: ${colors.white};

  picture,
  img {
    width: 32px;
    height: 32px;
  }
`;

const ShowMore = styled.button<{ isOpened?: boolean }>`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.main};

  svg {
    margin-left: 5px;
    transition: 0.3s;

    path {
      fill: ${colors.main};
    }
  }

  ${({ isOpened }) =>
    isOpened &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;
