import React from 'react';
import styled, { css } from 'styled-components';
import { SwiperSlide } from 'swiper/react';

import { media } from '@/utils/mixin';
import { ContentContainer } from '@/modules/Service/common.styles';
import DetailedCarBaseSlider from '@/components/DetailedCarBaseSlider';
import PostCard from '@/components/PostCard';
import ServiceBanner from '@/modules/Service/components/ServiceBanner';

import { OffersBlockProps } from './types';

function OffersBlock({ title, offers }: OffersBlockProps) {
  if (!offers || offers.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Title>{title}</Title>

        <ContentWrapper>
          {offers.length !== 1 ? (
            <DetailedCarBaseSlider isButtonHidden={offers.length < 5}>
              {offers.map((offer, index) => (
                <SwiperSlide key={index}>
                  <PostCard {...offer} />
                </SwiperSlide>
              ))}
            </DetailedCarBaseSlider>
          ) : (
            <ServiceBanner
              title={offers[0].title ?? ''}
              image={offers[0].image?.url ?? ''}
              link={offers[0].url ?? ''}
            />
          )}
        </ContentWrapper>
      </ContentContainer>
    </Component>
  );
}

export default OffersBlock;

const Component = styled.section`
  position: relative;
  padding: 30px 0;

  ${media.mobile(css`
    padding: 20px 0;
  `)}
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
  `)}
`;

const ContentWrapper = styled.div`
  margin-top: 30px;

  ${media.tabletSmall(css`
    margin-top: 20px;
  `)}

  .swiper-slide {
    max-width: 290px;
    height: auto;
  }
`;
