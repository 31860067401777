import React from 'react';
import styled from 'styled-components';

import { useMedia } from '@tager/web-core';

import { ContentContainer } from '@/modules/Service/common.styles';

import { ServicesBlockProps } from './types';
import Desktop from './components/Desktop';
import Mobile from './components/Mobile';

function ServicesBlock(props: ServicesBlockProps) {
  const isMobile = useMedia('(max-width: 767.9px)');

  return (
    <Component>
      <ContentContainer>
        {!isMobile ? <Desktop {...props} /> : <Mobile {...props} />}
      </ContentContainer>
    </Component>
  );
}

export default ServicesBlock;

const Component = styled.section``;
