import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';
import SwiperCore, { Pagination } from 'swiper';

import ServiceDealerCard from '@/modules/Service/components/ServiceDealerCard';
import { colors } from '@/constants/theme';

import { DealersSliderProps } from './types';

SwiperCore.use([Pagination]);

function DealersSlider({ dealers }: DealersSliderProps) {
  if (!dealers || dealers.length === 0) {
    return null;
  }

  return (
    <Component>
      <Swiper
        slidesPerView="auto"
        speed={600}
        spaceBetween={20}
        pagination={{
          el: '.swiper-pagination-dealers',
          type: 'bullets',
          clickable: true,
        }}
      >
        {dealers.map((dealer, index) => (
          <SwiperSlide key={index}>
            <ServiceDealerCard {...dealer} />
          </SwiperSlide>
        ))}
      </Swiper>

      <PaginationComponent className="swiper-pagination-dealers" />
    </Component>
  );
}

export default DealersSlider;

const Component = styled.div`
  position: relative;

  .swiper-slide {
    height: auto;
  }
`;

const PaginationComponent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .swiper-pagination-bullet {
    cursor: pointer;
    width: 7px;
    height: 7px;
    margin: 0 3px;
    background: ${(props) => props.theme.black};
  }

  .swiper-pagination-bullet-active {
    background: ${colors.main};
  }
`;
