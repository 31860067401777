import React from 'react';

import ServiceForm from '@/components/Forms/ServiceForm';
import {
  getServicePageOptions,
  handleSubmitServiceForm,
} from '@/modules/Service/common.helpers';
import { useTypedSelector } from '@/store/store';
import {
  getServiceBrandLanding,
  getServiceBrands,
  getServiceBrandServiceLanding,
  getServiceDealers,
} from '@/store/reducers/service';
import { OptionType } from '@/typings/common';
import { initialServiceFormValues } from '@/components/Forms/ServiceForm/helpers';
import { useServiceContext } from '@/modules/Service/common.hooks';

function Form() {
  const serviceDealers = useTypedSelector(getServiceDealers);
  const serviceBrands = useTypedSelector(getServiceBrands);
  const serviceBrandLanding = useTypedSelector(getServiceBrandLanding);
  const serviceBrandServiceLanding = useTypedSelector(
    getServiceBrandServiceLanding
  );

  const foundServiceDealers = serviceDealers
    .filter((dealer) =>
      dealer.brands.some((brand) => brand.name === serviceBrandLanding?.name)
    )
    .filter((dealer) =>
      serviceBrandServiceLanding?.dealers.some(
        (serviceDealer) => serviceDealer === dealer.name
      )
    );

  const { brands, dealers } = getServicePageOptions(
    foundServiceDealers,
    serviceBrands
  );
  const { selectedDealer } = useServiceContext();

  const foundDealer = (selectedDealer &&
    dealers.find((dealer) => dealer.dealer.value === selectedDealer.name)
      ?.dealer) ?? { label: '', value: '' };

  if (!serviceBrandServiceLanding) {
    return null;
  }

  const { name, formEvent } = serviceBrandServiceLanding;

  const foundBrand = brands.find(
    (brand) =>
      brand.label.toLowerCase() === serviceBrandLanding?.name?.toLowerCase()
  );

  const service: OptionType = { label: name, value: name };

  if (!foundBrand) {
    return null;
  }

  return (
    <ServiceForm
      title="Запись на сервис"
      buttonTitle="Записаться"
      options={{ brands: [foundBrand], dealers, services: [service] }}
      initialFields={{
        ...initialServiceFormValues,
        brand: foundBrand,
        service,
      }}
      setFields={{ dealer: foundDealer }}
      disabledFields={{ brand: true, service: true }}
      onSubmit={(values) =>
        handleSubmitServiceForm(values, serviceDealers, formEvent ?? '')
      }
      isMobile
    />
  );
}

export default Form;
