import React, { useEffect, useRef, useState } from 'react';
import { Swiper } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import styled, { css } from 'styled-components';

import ArrowIcon from '@/assets/svg/detailed-car/arrow.svg';
import { colors } from '@/constants/theme';
import { DetailedCarBaseSliderProps } from '@/components/DetailedCarBaseSlider/DetailedCarBaseSlider.types';

SwiperCore.use([Navigation]);

function DetailedCarBaseSlider({
  children,
  isButtonHidden,
}: DetailedCarBaseSliderProps) {
  const prevNavButtonRef = useRef<HTMLButtonElement>(null);
  const nextNavButtonRef = useRef<HTMLButtonElement>(null);
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);

  useEffect(() => {
    if (swiper) {
      swiper.update();
    }
  }, [swiper]);

  return (
    <Component>
      <Swiper
        onSwiper={setSwiper}
        slidesPerView={'auto'}
        spaceBetween={20}
        navigation={{
          prevEl: prevNavButtonRef.current,
          nextEl: nextNavButtonRef.current,
        }}
      >
        {children}
      </Swiper>
      {isButtonHidden || (
        <NavButtons>
          <NavButton ref={prevNavButtonRef} prev>
            <ArrowIcon />
          </NavButton>
          <NavButton ref={nextNavButtonRef} next>
            <ArrowIcon />
          </NavButton>
        </NavButtons>
      )}
    </Component>
  );
}

const Component = styled.div<{ isRelatedCars?: boolean }>`
  position: relative;
  .swiper-container {
    overflow: hidden;
    height: 99%;
  }
`;

const NavButtons = styled.div`
  max-width: 128px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: -76px;
  right: 0;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const NavButton = styled.button<{ prev?: boolean; next?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 0.7px solid ${colors.gray400};
  overflow: hidden;

  svg path {
    fill: ${colors.main};
  }

  &.swiper-button-disabled {
    pointer-events: none;

    svg path {
      fill: #dcdcdc !important;
    }
  }

  svg {
    width: 9px;
    height: 16px;
  }

  ${(props) =>
    props.prev
      ? css`
          svg {
            transform: rotate(-180deg);
          }
        `
      : css``}
`;

export default DetailedCarBaseSlider;
