import React from 'react';
import styled from 'styled-components';

import Link from '@/components/Link';
import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';

import { ServiceCardProps } from './types';
import ArrowIcon from './assets/arrow-right.svg';

function ServiceCard({ image, title, description, link }: ServiceCardProps) {
  return (
    <Component to={link}>
      <Image loading="lazy" src={image} alt={title} />

      <Content>
        <Title>{title}</Title>
        <Bottom>
          <Description>{description}</Description>

          <Button>
            <ArrowIcon />
          </Button>
        </Bottom>
      </Content>
    </Component>
  );
}

export default ServiceCard;

const Button = styled.button`
  flex: 0 0 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid ${colors.main};
  margin-left: 10px;
  transition: 0.3s;
`;

const Component = styled(Link)`
  display: flex;
  flex-direction: column;
  padding: 30px 20px 20px;
  background: ${(props) => props.theme.white_1};
  border: 1px solid ${(props) => props.theme.gray1300_alpha50};
  border-radius: 10px;
  transition: 0.3s;

  &:hover {
    ${Button} {
      background: ${colors.main};

      svg {
        path {
          fill: ${colors.white};
        }
      }
    }
  }
`;

const Image = styled(Picture)`
  position: relative;
  padding-top: 61.946%;

  picture,
  img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: contain;
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 39px;
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: ${(props) => props.theme.black200};
`;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.blue100};
`;
