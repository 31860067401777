import React from 'react';
import styled from 'styled-components';

import { ServiceProvider } from '@/modules/Service/ServiceProvider';

import Hero from './components/Hero';
import Dealers from './components/Dealers';
import Services from './components/Services';

export function ServiceSingle() {
  return (
    <ServiceProvider>
      <Component>
        <Hero />
        <Services />
        <Dealers />
      </Component>
    </ServiceProvider>
  );
}

const Component = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
`;
