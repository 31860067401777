import React from 'react';
import styled, { css } from 'styled-components';
import GoogleMapReact from 'google-map-react';

import { media } from '@/utils/mixin';

import { mapProps } from './constants';
import { DealersMapProps } from './types';
import Marker from './components/Marker';

function DealersMap({ dealers, isMobile }: DealersMapProps) {
  if (!dealers || dealers.length === 0) {
    return null;
  }

  return (
    <Component>
      <MapWrapper>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.NEXT_PUBLIC_API_KEY_GOOGLE_MAP ?? '',
          }}
          center={mapProps.center}
          defaultZoom={!isMobile ? mapProps.zoom.desktop : mapProps.zoom.mobile}
        >
          {dealers.map((dealer, index) => (
            <Marker key={index} {...dealer} />
          ))}
        </GoogleMapReact>
      </MapWrapper>
    </Component>
  );
}

export default DealersMap;

const Component = styled.div`
  position: relative;
  padding-top: 41.177%;
  border-radius: 10px;
  overflow: hidden;

  ${media.tablet(css`
    padding-top: 65.177%;
  `)}

  ${media.mobile(css`
    padding-top: 138.487%;
    margin: 0 -15px;
    border-radius: initial;
  `)}
`;

const MapWrapper = styled.div`
  position: absolute;
  inset: 0;
`;
