export const mapProps = {
  center: {
    lat: 53.91,
    lng: 27.55,
  },
  zoom: {
    desktop: 11.5,
    mobile: 10.6,
  },
};
