import * as Yup from 'yup';
import { omit } from 'lodash';

import {
  ConsultationFormSecondaryFields,
  ConsultationFormSecondaryFieldsConfig,
} from './types';

export const getResultConsultationFormSecondaryValues = (
  values: ConsultationFormSecondaryFields,
  fieldsConfig?: ConsultationFormSecondaryFieldsConfig
) => {
  return omit(values, !fieldsConfig?.withMessage ? 'message' : '');
};

export const initialConsultationFormSecondaryValues: ConsultationFormSecondaryFields =
  {
    name: '',
    phone: '',
    message: '',
  };

export const validationConsultationFormSecondarySchema: Yup.SchemaOf<ConsultationFormSecondaryFields> =
  Yup.object().shape({
    name: Yup.string().required('Укажите имя'),
    phone: Yup.string().required('Укажите номер телефона'),
    message: Yup.string(),
  });
