import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/Service/common.styles';
import ServiceForm from '@/components/Forms/ServiceForm';
import { useServiceMainData } from '@/modules/Service/pages/ServiceMain/hooks';
import { media } from '@/utils/mixin';
import { useTypedSelector } from '@/store/store';
import { getServiceBrands, getServiceDealers } from '@/store/reducers/service';
import {
  getServicePageOptions,
  handleSubmitServiceForm,
} from '@/modules/Service/common.helpers';
import { OptionType } from '@/typings/common';
import { useServiceContext } from '@/modules/Service/common.hooks';

function Form() {
  const { formTitle, formButtonTitle, formServices, formGoogleEvent } =
    useServiceMainData();
  const serviceDealers = useTypedSelector(getServiceDealers);
  const serviceBrands = useTypedSelector(getServiceBrands);

  const { brands, dealers } = getServicePageOptions(
    serviceDealers,
    serviceBrands
  );
  const { selectedDealer } = useServiceContext();

  const foundDealer = (selectedDealer &&
    dealers.find((dealer) => dealer.dealer.value === selectedDealer.name)
      ?.dealer) ?? { label: '', value: '' };

  const services: OptionType[] =
    formServices &&
    formServices.map(({ name }) => ({
      label: name ?? '',
      value: name ?? '',
    }));

  return (
    <Component id="service">
      <ContentContainer>
        <ServiceForm
          title={formTitle ?? ''}
          buttonTitle={formButtonTitle ?? ''}
          options={{ brands, dealers, services }}
          setFields={{ dealer: foundDealer }}
          onSubmit={(values) =>
            handleSubmitServiceForm(
              values,
              serviceDealers,
              formGoogleEvent ?? ''
            )
          }
        />
      </ContentContainer>
    </Component>
  );
}

export default Form;

const Component = styled.section`
  padding: 30px 0;

  ${media.mobile(css`
    padding: 20px 0;
  `)}
`;
