import React from 'react';

import DealersBlock from '@/modules/Service/blocks/DealersBlock';
import { useTypedSelector } from '@/store/store';
import {
  getServiceBrandLanding,
  getServiceBrands,
  getServiceDealers,
} from '@/store/reducers/service';
import {
  getServiceDealersData,
  getServicePageOptions,
  handleServiceClick,
} from '@/modules/Service/common.helpers';
import { useServiceContext } from '@/modules/Service/common.hooks';

function Dealers() {
  const serviceDealers = useTypedSelector(getServiceDealers);
  const serviceBrands = useTypedSelector(getServiceBrands);
  const serviceBrandLanding = useTypedSelector(getServiceBrandLanding);
  const { setSelectedDealer } = useServiceContext();

  const foundServiceDealers = serviceDealers.filter((dealer) =>
    dealer.brands.some((brand) => brand.name === serviceBrandLanding?.name)
  );

  const { brands, cities } = getServicePageOptions(
    foundServiceDealers,
    serviceBrands
  );
  const dealers = getServiceDealersData(foundServiceDealers);

  return (
    <DealersBlock
      title="Сервисные центры"
      dealers={dealers.map((dealer) => ({
        ...dealer,
        onClick: (dealer) => handleServiceClick(dealer, setSelectedDealer),
      }))}
      options={{ brands, cities }}
    />
  );
}

export default Dealers;
