import React from 'react';

import ServiceForm from '@/components/Forms/ServiceForm';
import {
  getServicePageOptions,
  handleSubmitServiceForm,
} from '@/modules/Service/common.helpers';
import { useTypedSelector } from '@/store/store';
import {
  getServiceBrands,
  getServiceDealers,
  getServiceSingleLanding,
} from '@/store/reducers/service';
import { OptionType } from '@/typings/common';
import { initialServiceFormValues } from '@/components/Forms/ServiceForm/helpers';
import { useServiceContext } from '@/modules/Service/common.hooks';

function Form() {
  const serviceDealers = useTypedSelector(getServiceDealers);
  const serviceBrands = useTypedSelector(getServiceBrands);
  const serviceSingleLanding = useTypedSelector(getServiceSingleLanding);

  const { brands, dealers } = getServicePageOptions(
    serviceDealers,
    serviceBrands
  );
  const { selectedDealer } = useServiceContext();

  const foundDealer = (selectedDealer &&
    dealers.find((dealer) => dealer.dealer.value === selectedDealer.name)
      ?.dealer) ?? { label: '', value: '' };

  if (!serviceSingleLanding) {
    return null;
  }

  const { name, formEvent } = serviceSingleLanding;

  const service: OptionType = { label: name, value: name };

  return (
    <ServiceForm
      title="Запись на сервис"
      buttonTitle="Записаться"
      options={{ brands, dealers, services: [service] }}
      initialFields={{
        ...initialServiceFormValues,
        service,
      }}
      setFields={{ dealer: foundDealer }}
      disabledFields={{ service: true }}
      onSubmit={(values) =>
        handleSubmitServiceForm(values, serviceDealers, formEvent ?? '')
      }
      isMobile
    />
  );
}

export default Form;
