import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';
import Link from '@/components/Link';

import { ServiceBannerProps } from './types';

function ServiceBanner({
  title,
  image,
  imageMobile,
  subtitle,
  description,
  link,
}: ServiceBannerProps) {
  return !link ? (
    <Component>
      <Content>
        <Title>{title}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {description && <Description>{description}</Description>}
      </Content>

      {image && (
        <Background
          loading="lazy"
          desktop={convertThumbnailToPictureImage({ url: image })}
          laptop={convertThumbnailToPictureImage({ url: image })}
          tabletSmall={convertThumbnailToPictureImage({ url: image })}
          mobile={convertThumbnailToPictureImage({
            url: imageMobile ? imageMobile : image,
          })}
        />
      )}
    </Component>
  ) : (
    <Link to={link}>
      <Component>
        <Content>
          <Title>{title}</Title>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
          {description && <Description>{description}</Description>}
        </Content>

        <Background
          loading="lazy"
          desktop={convertThumbnailToPictureImage({ url: image })}
          laptop={convertThumbnailToPictureImage({ url: image })}
          tabletSmall={convertThumbnailToPictureImage({ url: image })}
          mobile={convertThumbnailToPictureImage({
            url: imageMobile ? imageMobile : image,
          })}
        />
      </Component>
    </Link>
  );
}

export default ServiceBanner;

const Component = styled.div`
  position: relative;
  padding: 70px 60px;
  min-height: 290px;

  ${media.mobile(css`
    display: flex;
    align-items: flex-end;
    min-height: 434px;
    padding: 20px;
  `)}
`;

const Content = styled.div`
  z-index: 2;
  position: relative;
  max-width: 540px;
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  margin: 0;
  color: ${colors.white};

  ${media.mobile(css`
    font-size: 30px;
    line-height: 35px;
  `)}
`;

const Subtitle = styled.p`
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  margin-top: 20px;
  color: ${colors.white};

  ${media.mobile(css`
    font-size: 24px;
    line-height: 28px;
    margin-top: 10px;
  `)}
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 19px;
  margin-top: 10px;
  color: ${colors.white};
`;

const Background = styled(Picture)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  &::before {
    z-index: 1;
    content: '';
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #00132d 6.25%, rgba(0, 19, 45, 0) 100%);
    border-radius: 10px;

    ${media.mobile(css`
      background: linear-gradient(
        360deg,
        #00132d 32.17%,
        rgba(0, 19, 45, 0) 93.75%
      );
      border-radius: 10px 10px 0 0;
    `)}
  }

  picture,
  img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;

    ${media.mobile(css`
      border-radius: 10px 10px 0 0;
    `)}
  }

  img {
    object-fit: cover;
  }
`;
