import { isEmpty } from 'lodash';

import { Nullable } from '@tager/web-core';

import { OptionType } from '@/typings/common';
import {
  ServiceBrandLandingServiceListType,
  ServiceBrandsResponseType,
  ServiceDealersResponseType,
} from '@/services/service/typings';
import { ServiceDealerCardProps } from '@/modules/Service/components/ServiceDealerCard/types';
import { scrollToDomElement } from '@/utils/scroll';
import { ServiceFormFields } from '@/components/Forms/ServiceForm/types';
import { googleEvent } from '@/utils/events';
import { SEOTemplate } from '@/typings/model';
import { submitServiceRequest } from '@/services/leads/leads-service';

export const getServiceBrandsOptions = (
  serviceBrands: ServiceBrandsResponseType[]
): OptionType[] => {
  return serviceBrands.map(({ name }) => ({ label: name, value: name }));
};

export const getServicePageOptions = (
  serviceDealers: ServiceDealersResponseType[],
  serviceBrands: ServiceBrandsResponseType[]
): {
  brands: OptionType[];
  dealers: { dealer: OptionType; brands: OptionType[] }[];
  cities: OptionType[];
} => {
  return {
    brands: getServiceBrandsOptions(serviceBrands),
    dealers: serviceDealers.map((dealer) => ({
      dealer: {
        label: `${dealer.city?.name ? dealer.city?.name + ', ' : ''}${
          dealer.address
        }, ${dealer.name}`,
        value: dealer.name,
      },
      brands: dealer.brands.map((brand) => ({
        label: brand.name,
        value: brand.name,
      })),
    })),
    cities: [
      ...new Map(
        serviceDealers
          .map((dealer) => ({
            label: dealer?.city?.name ?? '',
            value: String(dealer?.city?.id ?? ''),
          }))
          .map((city) => [city.value, city])
      ).values(),
    ],
  };
};

export const getServiceDealersData = (
  serviceDealers: ServiceDealersResponseType[]
): ServiceDealerCardProps[] => {
  return serviceDealers.map((dealer) => ({
    image: dealer.image,
    name: dealer.name,
    city: dealer.city?.name ?? '',
    address: dealer.address ?? '',
    workTime: dealer.worktime ?? '',
    phone: dealer.phone ?? '',
    brands: dealer.brands.map((brand) => brand.name),
    lat: dealer.latitude ?? 0,
    lng: dealer.longitude ?? 0,
  }));
};

export const handleServiceClick = (
  selectedDealer?: Nullable<Omit<ServiceDealerCardProps, 'onClick'>>,
  setSelectedDealer?: (
    dealer: Nullable<Omit<ServiceDealerCardProps, 'onClick'>>
  ) => void
) => {
  const element = document.getElementById('service');

  if (!element) {
    return;
  }

  if (selectedDealer && setSelectedDealer) {
    setSelectedDealer(selectedDealer);
  }

  scrollToDomElement(element);
};

export const handleConsultationClick = () => {
  const element = document.getElementById('consultation');

  if (!element) {
    return;
  }

  scrollToDomElement(element);
};

export const handleSubmitServiceForm = async (
  values: ServiceFormFields,
  dealers: ServiceDealersResponseType[],
  googleEventType?: string
): Promise<void> => {
  const dealerId = Number(
    dealers.find((dealer) => dealer.name === values.dealer.value)?.id
  );

  try {
    await submitServiceRequest({
      ...values,
      brand: values.brand.value,
      dealer: dealerId,
      service: values.service.value,
    }).then(() => {
      if (googleEventType) {
        googleEvent(googleEventType);
      }
    });
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const getServicePageSEO = (
  seoTemplate: Nullable<SEOTemplate>,
  service?: string,
  brand?: string,
  brandRu?: string,
  parentService?: string
): Nullable<SEOTemplate> => {
  if (!seoTemplate) {
    return null;
  }

  const { title, description, keywords, openGraphImage, h1 } = seoTemplate;

  const replaceText = (text: Nullable<string>): Nullable<string> => {
    return (
      text
        ?.replaceAll('{{service}}', service ?? '')
        ?.replaceAll('{{service|lowercase}}', service?.toLowerCase() ?? '')
        ?.replaceAll(
          '{{parent_service|lowercase}}',
          parentService?.toLowerCase() ?? ''
        )
        ?.replaceAll('{{parent_service}}', parentService ?? '')
        ?.replaceAll('{{brand}}', brand ?? '')
        ?.replaceAll('{{brand|lowercase}}', brand?.toLowerCase() ?? '')
        ?.replaceAll('{{brand_ru}}', brandRu ? brandRu : brand ?? '')
        ?.replaceAll(
          '{{brand_ru|lowercase}}',
          brandRu ? brandRu : (brand ?? '').toLowerCase()
        ) ?? text
    );
  };

  return {
    title: replaceText(title),
    description: replaceText(description),
    keywords,
    openGraphImage,
    h1,
  };
};

export const getServiceListItemsCount = (
  serviceList: ServiceBrandLandingServiceListType[]
): number[] => {
  if (!serviceList || serviceList.length === 0 || isEmpty(serviceList[0])) {
    return [];
  }

  return serviceList.reduce<number[]>((acc, currentItem) => {
    return [
      ...acc,
      currentItem?.children ? currentItem.children.length + 1 : 1,
    ];
  }, []);
};

export const getServiceListColumns = (
  serviceList: ServiceBrandLandingServiceListType[],
  columnsCount: number
): {
  items: ServiceBrandLandingServiceListType[];
  count: number;
}[] => {
  if (!serviceList || serviceList.length === 0 || isEmpty(serviceList[0])) {
    return [];
  }

  const result: {
    items: ServiceBrandLandingServiceListType[];
    count: number;
  }[] = [];
  const serviceItemsCount = getServiceListItemsCount(serviceList);
  const maxItemsInColumn =
    serviceItemsCount.reduce<number>(
      (acc, currentValue) => acc + currentValue,
      0
    ) / columnsCount;

  serviceList.forEach((service, index) => {
    if (
      !result ||
      result.length === 0 ||
      result[result.length - 1].count > maxItemsInColumn
    ) {
      result.push({ items: [service], count: serviceItemsCount[index] });
    } else if (result[result.length - 1].count < maxItemsInColumn) {
      result[result.length - 1].items.push(service);
      result[result.length - 1].count += serviceItemsCount[index];
    }
  });

  return result;
};
