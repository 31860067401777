import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

export const Title = styled.p`
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
  `)}
`;
