import { Page } from '@/typings/page';
import useCurrentPage from '@/hooks/useCurrentPage';
import useSettingItem from '@/hooks/useSettingItem';
import { usePageUrl } from '@/hooks/usePageUrl';
import { getFirstDateOfYear, getLastDateOfYear } from '@/utils/common';
import { getEventMicromarking } from '@/utils/seo';

import { ServiceMain } from './types';

export function useServiceMainData(): Page<ServiceMain>['templateFields'] {
  return useCurrentPage<Page<ServiceMain>>().templateFields;
}

export function useServiceMainEventMicromarking(): string[] {
  const { seoParams } = useCurrentPage();
  const organization = useSettingItem('ORGANIZATION_NAME');
  const address = useSettingItem('MAIN_ADDRESS');
  const url = usePageUrl();

  const eventMicromarkingNames = [
    '✔️ Широкий спектр услуг',
    '🔥 Гарантия',
    '🔥 Высокие стандарты',
  ];
  const eventMicromarkingData = eventMicromarkingNames.map((name) => ({
    image: seoParams.openGraphImage ?? '',
    name,
    description: seoParams.description ?? '',
    startDate: getFirstDateOfYear(),
    endDate: getLastDateOfYear(),
    url,
    organization: organization ?? '',
    address: address ?? '',
  }));

  return eventMicromarkingData.map((data) => getEventMicromarking(data));
}
