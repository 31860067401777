import React from 'react';
import styled, { css } from 'styled-components';
import { SwiperSlide } from 'swiper/react';

import { ServicesBlockProps } from '@/modules/Service/blocks/ServicesBlock/types';
import Picture from '@/components/Picture';
import { Title } from '@/modules/Service/blocks/ServicesBlock/common.styles';
import { media } from '@/utils/mixin';
import ServiceCard from '@/modules/Service/components/ServiceCard';
import DetailedCarBaseSlider from '@/components/DetailedCarBaseSlider';

import man from './assets/man.png';

function Desktop({ title, items, withoutImage }: ServicesBlockProps) {
  return (
    <Component>
      {!withoutImage && <Image loading="lazy" src={man} />}

      <Right>
        <Title>{title}</Title>

        <Services>
          <DetailedCarBaseSlider
            isButtonHidden={withoutImage ? items.length < 6 : items.length < 5}
          >
            {items &&
              items.length !== 0 &&
              items.map((item, index) => (
                <SwiperSlide key={index}>
                  <ServiceCard {...item} />
                </SwiperSlide>
              ))}
          </DetailedCarBaseSlider>
        </Services>
      </Right>
    </Component>
  );
}

export default Desktop;

const Component = styled.div`
  display: flex;
`;

const Image = styled(Picture)`
  flex: 0 0 290px;
  margin-top: 44px;
  margin-right: 21px;

  ${media.tablet(css`
    display: none;
  `)}
`;

const Right = styled.div`
  width: 100%;
  min-width: 0;
`;

const Services = styled.div`
  margin-top: 30px;

  .swiper-slide {
    max-width: 289px;
    height: auto;

    & > a {
      height: 100%;
    }
  }
`;
