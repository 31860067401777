import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { ServiceDealerCardProps } from '@/modules/Service/components/ServiceDealerCard/types';
import BlueIcon from '@/assets/svg/icon_map_blue.svg';
import ServiceDealerCard from '@/modules/Service/components/ServiceDealerCard';
import { Content as ServiceDealerCardContent } from '@/modules/Service/components/ServiceDealerCard/ServiceDealerCard';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';
import { media } from '@/utils/mixin';

function Marker(marker: ServiceDealerCardProps) {
  const [isPopupOpened, setPopupOpened] = useState(false);
  const componentRef = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(componentRef, () => {
    if (isPopupOpened) {
      setPopupOpened(false);
    }
  });

  const handlePopupOpen = (event: React.SyntheticEvent) => {
    setPopupOpened(!isPopupOpened);
    event.stopPropagation();
  };

  return (
    <Component ref={componentRef}>
      <StyledBlueIcon onClick={handlePopupOpen} />
      <Popup isPopupOpened={isPopupOpened}>
        <Card onClick={(event) => event.stopPropagation()}>
          <ServiceDealerCard {...marker} />
        </Card>
      </Popup>
    </Component>
  );
}

export default Marker;

const Component = styled.div`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  svg {
    cursor: pointer;
  }
`;

const Popup = styled.div<{
  isPopupOpened: boolean;
}>`
  z-index: 100;
  display: none;
  position: absolute;
  min-width: 755px;
  bottom: 70px;
  box-shadow: 0 5px 20px #717171;

  ${media.tablet(css`
    min-width: 650px;
  `)}

  ${media.mobile(css`
    min-width: 330px;
  `)}

  ${({ isPopupOpened }) =>
    isPopupOpened &&
    css`
      display: block;
    `}
`;

const Card = styled.div`
  cursor: initial;

  ${media.mobile(css`
    ${ServiceDealerCardContent} {
      border-radius: 10px;
      border-top-color: ${(props) => props.theme.gray1300_alpha50};
    }
  `)}
`;

const StyledBlueIcon = styled(BlueIcon)`
  position: absolute;
  top: -50px;
  left: -20px;
`;
