import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/Service/common.styles';
import { media } from '@/utils/mixin';
import { useTypedSelector } from '@/store/store';
import { getServiceSingleLanding } from '@/store/reducers/service';
import ServiceIntroBlock from '@/modules/Service/blocks/ServiceIntroBlock';
import ServiceDescriptionBlock from '@/modules/Service/blocks/ServiceDescriptionBlock';
import { Breadcrumbs } from '@/components/Breadcrumbs';

import Form from './components/Form';

function Hero() {
  const serviceSingleLanding = useTypedSelector(getServiceSingleLanding);

  if (!serviceSingleLanding) {
    return null;
  }

  const { name, preview, body, image, breadcrumbs } = serviceSingleLanding;

  return (
    <Component>
      <ContentContainer>
        <Content>
          <BreadcrumbsWrapper>
            <Breadcrumbs
              links={[
                { label: 'Главная', link: '/' },
                {
                  label: 'Сервис',
                  link: '/service',
                },
                ...breadcrumbs,
              ]}
            />
          </BreadcrumbsWrapper>

          <IntroWrapper>
            <ServiceIntroBlock
              title={name}
              text={preview ?? ''}
              image={image}
            />
          </IntroWrapper>

          <DescriptionWrapper>
            <ServiceDescriptionBlock text={body ?? ''} />
          </DescriptionWrapper>

          <FormWrapper id="service">
            <Form />
          </FormWrapper>
        </Content>
      </ContentContainer>
    </Component>
  );
}

export default Hero;

const Component = styled.section`
  padding: 40px 0 30px;

  ${media.mobile(css`
    padding: 20px 0;
  `)}
`;

const Content = styled.div`
  display: grid;
  grid-template-areas:
    'breadcrumbs form'
    'intro form'
    'description  form';
  grid-template-columns: 1fr 350px;
  grid-template-rows: auto auto 1fr;

  ${media.mobile(css`
    grid-template-areas:
      'intro'
      'form'
      'description';
    grid-template-columns: 1fr;
  `)}
`;

const BreadcrumbsWrapper = styled.div`
  grid-area: breadcrumbs;
  align-self: start;

  ${media.tablet(css`
    display: none;
  `)}
`;

const IntroWrapper = styled.div`
  grid-area: intro;
  align-self: start;
  margin-top: 20px;

  ${media.tablet(css`
    margin-top: 0;
  `)}
`;

const DescriptionWrapper = styled.div`
  grid-area: description;
  align-self: start;
  margin-top: 30px;
`;

const FormWrapper = styled.div`
  grid-area: form;
  margin-left: 20px;

  ${media.mobile(css`
    margin-left: 0;
    margin-top: 40px;
  `)}
`;
